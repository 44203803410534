<template>
  <div>

    <HeaderPersonal />

    <div class="section color mid-line">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container last form two w-container">
        <!-- Кредитный рейтинг -->
        <div class="form-regidet profil w-form">
          <form id="wf-form-signup-2" name="wf-form-signup-2" data-name="signup" method="get" data-ms-form="signup" class="form-input-regidet reiting" data-wf-page-id="6667fc8089ed7ce9f6a63d6e" data-wf-element-id="d73203a7-f081-4301-5c74-acb811806f90">
            <div class="tittle-form profil">
              <h1 class="heading-form white">Мой кредитный<br>рейтинг</h1>
              <div class="reitig-hide">
                <div class="text-ball bold">{{getCreditRating()}}</div>
                <div class="text-ball">{{getWord(getCreditRating)}}</div>
              </div>
            </div>
            <div class="w-layout-vflex flex-form">
              <a id="openLabel" @click="goToCreditTest" class="button-bust white w-button">Рассчитать кредитный рейтинг</a>
              <link rel="prerender" @click="goToCreditTest">
            </div>
          </form>
        </div>

        <!-- Личные данные -->
        <div class="form-regidet profil w-form">
          <form id="wf-form-signup-2" name="wf-form-signup-2" data-name="signup" method="get" data-ms-form="signup" class="form-input-regidet" data-wf-page-id="6667fc8089ed7ce9f6a63d6e" data-wf-element-id="be470ab5-17a8-9531-d725-c70c94a6ea13">
            <v-form ref="formProfile" v-model="valid_profile" lazy-validation>
              <div class="tittle-form profil" style="margin-bottom: 15px;">
                <h1 class="heading-form">Личные данные</h1>
                <a v-if="!isRedactProfile" @click="redactProfile" class="item-edit w-inline-block">
                  <div class="text-classic-link link">Редактировать</div>
                  <img src="../../../../../public/images/Edit.svg" loading="lazy" alt="" class="icon-edit">
                </a>
                <a v-else @click="cancelRedactProfile" class="item-edit w-inline-block">
                  <div class="text-classic-link link">Отмена</div>
                  <img src="../../../../../public/images/close.svg" loading="lazy" alt="" class="icon-edit">
                </a>
              </div>
              <div class="w-layout-vflex flex-form">
                <div class="w-layout-hflex flex-input">
                  <div class="input-box" v-if="!isRedactProfile">
                    <label for="FIO-2" class="input-label">ФИО</label>
                    <input
                        v-model="profile_data.full_name"
                        :disabled="!isRedactProfile || isRedactProfileSend"
                        class="input-form w-input"
                        maxlength="256"
                        name="FIO-2"
                        data-name="FIO 2"
                        type="text"
                        id="FIO-2"
                        required="">
                  </div>
                  <div class="input-box">
                    <label for="Data-2" class="input-label">Дата рождения</label>
                    <v-text-field
                        id="Data-2"
                        name="Data-2"
                        outlined
                        type="date"
                        v-model="profile_data.birth_date"
                        :rules="dateRules"
                        :disabled="!isRedactProfile || isRedactProfileSend"
                    ></v-text-field>
                  </div>
                </div>
                <div class="w-layout-hflex flex-input" v-if="isRedactProfile">
                  <div class="input-box input-error">
                    <label for="SecondName-2" class="input-label">Фамилия</label>
                    <v-text-field
                        id="SecondName-2"
                        name="SecondName-2"
                        outlined
                        v-model="profile_data.second_name"
                        :rules="nameRules"
                        :disabled="isRedactProfileSend"
                    ></v-text-field>
                  </div>
                  <div class="input-box">
                    <label for="Name-2" class="input-label">Имя</label>
                    <v-text-field
                        id="Name-2"
                        name="Name-2"
                        outlined
                        inputmode="text"
                        v-model="profile_data.name"
                        :rules="nameRules"
                        :disabled="isRedactProfileSend"
                    ></v-text-field>
                  </div>
                  <div class="input-box">
                    <label for="MiddleName-3" class="input-label">Отчество</label>
                    <v-text-field
                        id="MiddleName-3"
                        name="MiddleName-3"
                        outlined
                        inputmode="text"
                        v-model="profile_data.middle_name"
                        :rules="nameRules"
                        :disabled="isRedactProfileSend"
                    ></v-text-field>
                  </div>
                </div>
                <div class="w-layout-hflex flex-input">
                  <div class="input-box">
                    <label for="Phone-2" class="input-label">Телефон</label>
                    <input
                        v-model="profile_data.phone"
                        disabled
                        class="input-form w-input"
                        maxlength="256"
                        name="Phone-2"
                        inputmode="numeric"
                        data-name="Phone 2"
                        type="tel"
                        id="Phone-2"
                        required="">
                  </div>
                  <div class="input-box">
                    <label for="Email-5" class="input-label">Email</label>
                    <v-text-field
                        id="Email-5"
                        name="Email-5"
                        outlined
                        type="email"
                        inputmode="email"
                        v-model="profile_data.email"
                        :rules="emailRules"
                        :disabled="!isRedactProfile || isRedactProfileSend"
                    ></v-text-field>
                  </div>
                </div>

                <div
                    class="w-layout-hflex flex-input"
                    v-if="isRedactProfile"
                >
                  <div class="input-box" style="display: flex;">
                    <a id="openLabel"
                       class="button-bust w-button"
                       :class="{ 'disable': isRedactProfileSend }"
                       @click="sendProfileChanges"
                    >
                      Отправить
                    </a>
                  </div>
                </div>
              </div>
            </v-form>
          </form>
        </div>

        <!-- Паспортные данные -->
        <div class="form-regidet profil w-form">
          <form id="wf-form-signup-2" name="wf-form-signup-2" data-name="signup" method="get" data-ms-form="signup" class="form-input-regidet" data-wf-page-id="6667fc8089ed7ce9f6a63d6e" data-wf-element-id="20ec3ff4-1027-f9a2-e2da-7601bdfd140e">
            <v-form ref="formPassport" v-model="valid_passport" lazy-validation>
              <div class="tittle-form profil" style="margin-bottom: 15px;">
                <h1 class="heading-form">Паспортные данные</h1>
                <a v-if="!isRedactPassport" @click="redactPassport" class="item-edit w-inline-block">
                  <div class="text-classic-link link">Редактировать</div><img src="../../../../../public/images/Edit.svg" loading="lazy" alt="" class="icon-edit">
                </a>
                <a v-else @click="cancelRedactPassport" class="item-edit w-inline-block">
                  <div class="text-classic-link link">Отмена</div><img src="../../../../../public/images/close.svg" loading="lazy" alt="" class="icon-edit">
                </a>
              </div>
              <div class="w-layout-vflex flex-form">
                <div class="w-layout-hflex flex-input">
                  <div class="input-box" v-if="!isRedactPassport">
                    <label for="Seria-and-Number" class="input-label">Серия и номер</label>
                    <input
                        :value="getPassport()"
                        :disabled="!isRedactPassport || isRedactPassportSend"
                        disabled
                        class="input-form w-input"
                        maxlength="256"
                        name="Seria-and-Number"
                        data-name="Seria and Number"
                        type="text"
                        id="Seria-and-Number"
                        required="">
                  </div>
                  <div class="w-layout-hflex flex-input" v-if="isRedactPassport">
                    <div class="input-box input-error">
                      <label for="Series" class="input-label">Серия</label>
                      <v-text-field
                          id="Series"
                          name="Series"
                          outlined
                          v-mask="'####'"
                          inputmode="numeric"
                          v-model="passport_data.series"
                          :rules="passportSeriesRules"
                          :disabled="isRedactPassportSend"
                      ></v-text-field>
                    </div>
                    <div class="input-box">
                      <label for="Number" class="input-label">Номер</label>
                      <v-text-field
                          id="Number"
                          name="Number"
                          outlined
                          v-mask="'######'"
                          inputmode="numeric"
                          v-model="passport_data.number"
                          :rules="passportNumberRules"
                          :disabled="isRedactPassportSend"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="input-box">
                    <label for="Kem-Vidan-8" class="input-label">Кем выдан</label>
                    <v-text-field
                        id="Kem-Vidan"
                        name="Kem-Vidan"
                        outlined
                        type="text"
                        inputmode="text"
                        v-model="passport_data.issued_by"
                        :rules="nameRules"
                        :disabled="!isRedactPassport || isRedactPassportSend"
                    ></v-text-field>
                  </div>
                </div>
                <div class="w-layout-hflex flex-input">
                  <div class="input-box">
                    <label for="Data-Vid-3" class="input-label">Дата выдачи</label>
                    <v-text-field
                        id="Data-Vid"
                        name="Data-Vid"
                        outlined
                        type="date"
                        v-model="passport_data.issued_at"
                        :rules="dateRules"
                        :disabled="!isRedactPassport || isRedactPassportSend"
                    ></v-text-field>
                  </div>
                  <div class="input-box">
                    <label for="Kod-5" class="input-label">Код подразделения</label>
                    <v-text-field
                        id="Kod-5"
                        name="Kod"
                        outlined
                        type="text"
                        v-mask="'###-###'"
                        inputmode="numeric"
                        v-model="passport_data.division_code"
                        :rules="divisionCodeRules"
                        :disabled="!isRedactPassport || isRedactPassportSend"
                    ></v-text-field>
                  </div>
                </div>
                <div class="w-layout-hflex flex-input">
                  <div class="input-box _50">
                    <label for="Place-Of-Birth-3" class="input-label">Место рождения (как в паспорте)</label>
                    <v-text-field
                        id="Place-Of-Birth-3"
                        name="Place-Of-Birth"
                        outlined
                        type="text"
                        inputmode="text"
                        v-model="passport_data.birth_place"
                        :rules="nameRules"
                        :disabled="!isRedactPassport || isRedactPassportSend"
                    ></v-text-field>
                  </div>
                </div>

                <div
                    class="w-layout-hflex flex-input"
                    v-if="isRedactPassport"
                >
                  <div class="input-box" style="display: flex;">
                    <a id="openLabel"
                       class="button-bust w-button"
                       :class="{ 'disable': isRedactPassportSend }"
                       @click="sendPassportChanges"
                    >
                      Отправить
                    </a>
                  </div>
                </div>

              </div>
            </v-form>
          </form>
        </div>

        <!-- Адрес -->
        <div class="form-regidet profil w-form">
          <form id="wf-form-signup-2" name="wf-form-signup-2" data-name="signup" method="get" data-ms-form="signup" class="form-input-regidet" data-wf-page-id="6667fc8089ed7ce9f6a63d6e" data-wf-element-id="f8f95e6f-16c2-fc24-c971-2c539f8e8690">
            <v-form ref="formAddress" v-model="valid_address" lazy-validation>
              <div class="tittle-form profil" style="margin-bottom: 15px">
                <h1 class="heading-form">Адрес</h1>
                <a v-if="!isRedactAddress" @click="redactAddress" class="item-edit w-inline-block">
                  <div class="text-classic-link link">Редактировать</div><img src="../../../../../public/images/Edit.svg" loading="lazy" alt="" class="icon-edit">
                </a>
                <a v-else @click="cancelRedactAddress" class="item-edit w-inline-block">
                  <div class="text-classic-link link">Отмена</div><img src="../../../../../public/images/close.svg" loading="lazy" alt="" class="icon-edit">
                </a>
              </div>
              <div class="w-layout-vflex flex-form">
                <div class="w-layout-hflex flex-input">
                  <div class="input-box" v-if="!isRedactAddress">
                    <label for="Registration-address" class="input-label">Адрес регистрации</label>
                    <input
                        :value="getAddress()"
                        disabled
                        class="input-form w-input"
                        maxlength="256"
                        name="Registration-address"
                        data-name="Registration address"
                        type="text"
                        id="Registration-address"
                        required="">
                  </div>
                </div>
                <div class="w-layout-hflex flex-input" v-if="isRedactAddress">
                  <div class="input-box input-error">
                    <label for="Region" class="input-label">Регион</label>
                    <v-text-field
                        id="Region"
                        name="Region"
                        outlined
                        inputmode="text"
                        v-model="address_data.region"
                        :rules="nameRules"
                        :disabled="isRedactAddressSend"
                    ></v-text-field>
                  </div>
                  <div class="input-box">
                    <label for="City" class="input-label">Город</label>
                    <v-text-field
                        id="City"
                        name="City"
                        outlined
                        inputmode="text"
                        v-model="address_data.city"
                        :rules="nameRules"
                        :disabled="isRedactAddressSend"
                    ></v-text-field>
                  </div>
                </div>
                <div class="w-layout-hflex flex-input" v-if="isRedactAddress">
                  <div class="input-box">
                    <label for="Street" class="input-label">Улица</label>
                    <v-text-field
                        id="Street"
                        name="Street"
                        outlined
                        inputmode="text"
                        v-model="address_data.street"
                        :rules="nameRules"
                        :disabled="isRedactAddressSend"
                    ></v-text-field>
                  </div>
                  <div class="input-box">
                    <label for="House" class="input-label">Дом</label>
                    <v-text-field
                        id="House"
                        name="House"
                        outlined
                        inputmode="text"
                        v-model="address_data.house"
                        :rules="houseNumberRules"
                        :disabled="isRedactAddressSend"
                    ></v-text-field>
                  </div>
                  <div class="input-box">
                    <label for="House" class="input-label">Квартира</label>
                    <v-text-field
                        id="Apartment"
                        name="Apartment"
                        outlined
                        type="text"
                        inputmode="text"
                        v-model="address_data.apartment"
                        :rules="apartmentRules"
                        :disabled="isRedactAddressSend"
                    ></v-text-field>
                  </div>
                </div>

                <div
                    class="w-layout-hflex flex-input"
                    v-if="isRedactAddress"
                >
                  <div class="input-box" style="display: flex;">
                    <a id="openLabel"
                       class="button-bust w-button"
                       :class="{ 'disable': isRedactAddressSend }"
                       @click="sendAddressChanges"
                    >
                      Отправить
                    </a>
                  </div>
                </div>

              </div>
            </v-form>
          </form>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import { Footer, HeaderPersonal } from '/src/app/shared/components';
import loadjs from 'loadjs';

export default {
  name: 'PersonalData',
  data() {
    return {
      isRedactProfile: false,
      isRedactAddress: false,
      isRedactPassport: false,
      copyProfileData: {},
      copyPassportData: {},
      copyAddressData: {},

      isRedactProfileSend: false,
      isRedactPassportSend: false,
      isRedactAddressSend: false,

      valid_profile: false,
      valid_passport: false,
      valid_address: false,

      dateRules: [
        v => !!v || 'Введите корректную дату',
        v => this.isValidDate(v) || 'Неправильный формат даты'
      ],
      nameRules: [
        v => !!v || 'Поле не может быть пустым',
        v => (v && v.length <= 256) || 'Максимальная длина 256 символов'
      ],
      apartmentRules: [
        v => !v || /^[0-9]*$/.test(v) || 'Номер квартиры должен содержать только цифры'
      ],
      houseNumberRules: [
        v => !!v || 'Поле не может быть пустым',
        v => /^[0-9a-zA-Zа-яА-Я]+$/.test(v) || 'Номер дома должен содержать только цифры или буквы'
      ],
      emailRules: [
        v => !!v || 'Поле не может быть пустым',
        v => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'Неправильный формат email'
      ],
      passportSeriesRules: [
        v => !!v || 'Введите серию паспорта',
        v => /^\d{4}$/.test(v) || 'Серия паспорта должна состоять из 4 цифр'
      ],
      passportNumberRules: [
        v => !!v || 'Введите номер паспорта',
        v => /^\d{6}$/.test(v) || 'Номер паспорта должен состоять из 6 цифр'
      ],
      divisionCodeRules: [
        v => !!v || 'Введите код подразделения',
        v => /^\d{3}-\d{3}$/.test(v) || 'Код подразделения должен быть в формате 000-000'
      ],
    };
  },
  components: {
    Footer,
    HeaderPersonal
  },
  computed: {
    profile_data() {
      if (this.isRedactProfile) {
        return this.copyProfileData;
      } else {
        return this.personal_data.profile;
      }
    },
    passport_data() {
      if (this.isRedactPassport) {
        return this.copyPassportData;
      } else {
        return this.personal_data.passport;
      }
    },
    address_data() {
      if (this.isRedactAddress) {
        return this.copyAddressData;
      } else {
        return this.personal_data.address;
      }
    },
    personal_data: {
      get() {
        return this.$store.getters.PERSONAL_DATA;
      },
      set(value) {
        this.$store.commit('SET_PERSONAL_DATA', value);
      }
    },
  },
  created() {
    this.$store.dispatch('GET_PERSONAL_DATA', 'all').catch({
      error: () => {
        this.$buefy.toast.open({
          message: 'Ошибка получения данных',
          type: 'is-danger'
        });
      }
    });
  },
  methods: {
    goToCreditTest() {
      this.$router.push('/credit-rating');
    },
    sendPassportChanges() {
      if (this.$refs.formPassport.validate()) {
        this.isRedactPassportSend = true;

        this.$store.dispatch('CHANGE_PASSPORT_DATA', this.passport_data)
            .then(() => {
              this.$buefy.toast.open({
                message: 'Сохранено!',
                type: 'is-success'
              });
            })
            .catch(() => {
              this.$buefy.toast.open({
                message: 'Ошибка сохранения',
                type: 'is-danger'
              });
            })
            .finally(() => {
              this.isRedactPassport = false;
              this.isRedactPassportSend = false;
            });
      } else {
        this.$buefy.toast.open({
          message: 'Неправильно заполнены поля',
          type: 'is-danger'
        });
      }
    },
    sendAddressChanges() {
      if (this.$refs.formAddress.validate()) {
        this.isRedactPassportSend = true;

        this.$store.dispatch('CHANGE_ADDRESS_DATA', this.address_data)
            .then(() => {
              this.$buefy.toast.open({
                message: 'Сохранено!',
                type: 'is-success'
              });
            })
            .catch(() => {
              this.$buefy.toast.open({
                message: 'Ошибка сохранения',
                type: 'is-danger'
              });
            })
            .finally(() => {
              this.isRedactAddress = false;
              this.isRedactAddressSend = false;
            });
      } else {
        this.$buefy.toast.open({
          message: 'Неправильно заполнены поля',
          type: 'is-danger'
        });
      }
    },
    cancelRedactAddress() {
      this.isRedactAddress = false;
      this.isRedactAddressSend = false;
    },
    cancelRedactPassport() {
      this.isRedactPassport = false;
      this.isRedactPassportSend = false;
    },
    cancelRedactProfile() {
      this.isRedactProfile = false;
      this.isRedactProfileSend = false;
    },

    sendProfileChanges() {
      if (this.$refs.formProfile.validate()) {
        this.isRedactProfileSend = true;

        this.$store.dispatch('CHANGE_PROFILE_DATA', this.profile_data)
            .then(() => {
              this.$buefy.toast.open({
                message: 'Сохранено!',
                type: 'is-success'
              });
            })
            .catch(() => {
              this.$buefy.toast.open({
                message: 'Ошибка сохранения',
                type: 'is-danger'
              });
            })
            .finally(() => {
              this.isRedactProfile = false;
              this.isRedactProfileSend = false;
            });
      } else {
        this.$buefy.toast.open({
          message: 'Неправильно заполнены поля',
          type: 'is-danger'
        });
      }
    },
    goToReg() {
      this.$router.push('/registration-step4');
    },
    getAddress() {
      if (this.address_data.region !== undefined) {
        let apart = this.address_data.apartment ? `, кв. ${this.address_data.apartment}` : '';
        return `${this.address_data.region}, ${this.address_data.city}, ул.${this.address_data.street}, ${this.address_data.house}${apart}`;
      } else {
        return '';
      }
    },
    getPassport() {
      if (this.passport_data.series && this.passport_data.number) {
        return `${this.passport_data.series} ${this.passport_data.number}`;
      } else {
        return '';
      }
    },
    redactProfile() {
      this.isRedactProfile = true
      this.copyProfileData = JSON.parse(JSON.stringify(this.personal_data.profile));
      this.splitFullName(this.personal_data.profile.full_name, this.copyProfileData);
    },
    redactPassport() {
      this.isRedactPassport = true
      this.copyPassportData = JSON.parse(JSON.stringify(this.personal_data.passport));
    },
    splitFullName(full_name, arr) {
      const parts = full_name.trim().split(/\s+/);

      arr.second_name = parts[0] || '';
      arr.name = parts[1] || '';
      arr.middle_name = parts[2] || '';
    },
    isValidDate(dateString) {
      // Проверка на пустую строку
      if (!dateString) return false;

      // Регулярное выражение для проверки формата YYYY-MM-DD
      const regEx = /^\d{4}-\d{2}-\d{2}$/;

      // Проверка соответствия формату
      if (!dateString.match(regEx)) return false;

      // Проверка, что дата валидна
      const d = new Date(dateString);
      const year = d.getFullYear();
      const month = d.getMonth() + 1;
      const day = d.getDate();

      // Проверка на правильное количество дней в месяце
      if (year < 1000 || year > 9999 || month < 1 || month > 12) return false;
      const daysInMonth = new Date(year, month, 0).getDate();
      if (day < 1 || day > daysInMonth) return false;

      return true;
    },
    redactAddress() {
      this.isRedactAddress = true
      this.copyAddressData = JSON.parse(JSON.stringify(this.personal_data.address));
    },
    getCreditRating() {
      return this.profile_data.credit_rating ? this.profile_data.credit_rating : 0;
    },
    getWord(num){
      return "балл" + this.getNoun(num)
    },
    getNoun(number) {
      let n = Math.abs(number);
      n %= 100;
      if (n >= 5 && n <= 20) {
        return 'ов';
      }
      n %= 10;
      if (n === 1) {
        return '';
      }
      if (n >= 2 && n <= 4) {
        return 'а';
      }
      return 'ов';
    }
  },
  mounted() {
    /* грузим jquery и webflow */
    loadjs(
        "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=652921c910bae02d8870dddf",
        {
          success: () => {
            loadjs('./js/webflow.js', {
              success: () => {
                console.log('Webflow loaded');
              },
              error: () => {
                console.error('Failed to load Webflow');
              }
            });
          },
          error: () => {
            console.error('Failed to load jQuery');
          }
        }
    );
  },
};
</script>

<style>
</style>
